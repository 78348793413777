<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:58:26
 * @LastEditTime: 2022-02-24 15:51:55
 * @LastEditors: aleaner
 * @Description: 店铺配置页面
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\wx-store\views\store-config.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="term-config">
    <div class="list-content" v-loading="loading">
      <div class="store-tips">
        <div class="tips-title">
          <img style="width: 28px;height:28px;margin-right: 5px"
            src="@/modules/system-admin/assets/images/icon-title.png"> 温馨提示
        </div>
        <div class="tips-content">
          <div class="item-tip"><span style="margin-right: 10px">·</span>未有微信小商店，前往 <a
              href="https://shop.weixin.qq.com/" target="_blank" class=" cursor-pointer">创建小商店</a>
          </div>
          <div class="item-tip"><span
              style="margin-right: 10px">·</span>如微信小商店为组件版需要将下方的组件版配置启用（目前仅支持与本后台绑定的小程序）<a
              href="https://www.yuque.com/ax101w/yz6xgk/vpv5sm" target="_blank"
              class=" cursor-pointer">微信小商店说明</a>
          </div>
        </div>
      </div>
      <el-form v-if="config" size="medium" :model="_data" label-position="right" label-width="70px"
        class="medium-form">
        <div class="modular">
          <p class="modular-title">店铺信息</p>
          <div class="modular-content" style="margin-left: 11px;">
            <el-form-item label="店铺信息：" style="margin-bottom: 13px">
              <el-form size="medium" :model="item" :rules="rules" label-position="right" label-width="85px"
                ref="listItem" class="list-form medium-form" v-for="(item,index) in config" :key="index">
                <el-button type="text" class="del-btn" @click="handleDel(index)">删除店铺</el-button>
                <el-form-item style="margin-bottom: 20px" label="店铺名称：" prop="name">
                  <el-input v-model="item.name" placeholder="请填写小商店名称"></el-input>
                </el-form-item>
                <el-form-item style="margin-bottom: 0px" label="APPID：" prop="app_id">
                  <el-input v-model="item.app_id" placeholder="请填写APPID"></el-input>
                </el-form-item>
              </el-form>
              <el-button class="add-btn" @click="handleAdd">添加新商店</el-button>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <p class="modular-title">小商店组件版配置</p>
          <div class="modular-content" style="margin-left: 11px;">
            <el-form-item label-width="0px">
              <el-switch v-model="plugin_status" active-text="启用" inactive-text="不启用" :active-value="1"
                :inactive-value="0"></el-switch>
              <div class="form-tip">
                <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
                <span>启用前，请先在微信小程序管理后台添加“小程序官方购物服务”插件，<a href="https://www.yuque.com/ax101w/yz6xgk/vpv5sm"
                    target="_blank" class=" cursor-pointer">查看添加插件指引</a></span>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import { getConfig, saveConfig } from "../api/store-config";
export default {
  components: { FixedActionBar },
  data() {
    return {
      loading: true,
      config: null,
      plugin_status: 1,
      rules: {
        name: [
          { required: true, message: "请填写小商店名称", trigger: "blur" },
        ],
        app_id: [{ required: true, message: "请填写APPID", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      getConfig()
        .then((res) => {
          this.config = res.data.config;
          this.plugin_status = res.data.plugin_status;
          // getMemberConfig().then(res => {
          //   this.config2 = res.data;
          this.loading = false;
          // }).catch(err => {
          //   this.loading = false;
          // })
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 添加小商店
    handleAdd() {
      const defaultData = {
        name: "",
        app_id: "",
        id: 0,
      };
      this.config.push(defaultData);
    },
    // 删除店铺
    handleDel(index) {
      this.$msgbox
        .confirm("确定要删除该店铺吗？删除后数据无法复原", "提示", {
          type: "error",
        })
        .then((res) => {
          this.config.splice(index, 1);
        })
        .catch((err) => {});
    },
    saveConfig() {
      let isPass = true;
      let index = -1;
      this.$refs.listItem.forEach((el, i) => {
        el.validate((valid) => {
          if (valid) {
            index = i;
          } else {
            isPass = false;
            return false;
          }
        });
      });
      if (index === this.$refs.listItem.length - 1 && isPass) {
        this.loading = true;
        saveConfig({
          config: this.config,
          plugin_status: this.plugin_status,
        })
          .then((res) => {
            // saveMemberConfig({
            //   config: this.config2
            // }).then(res => {
            this.$message.success(res.msg);
            this.loading = false;
            // }).catch(err => {
            //   this.loading = false;
            // })
          })
          .catch((err) => {
            this.loading = false;
          });
      }

      // this.$refs.listItem.validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     saveConfig({
      //       config: this.config,
      //     })
      //       .then((res) => {
      //         // saveMemberConfig({
      //         //   config: this.config2
      //         // }).then(res => {
      //         this.$message.success(res.msg);
      //         this.loading = false;
      //         // }).catch(err => {
      //         //   this.loading = false;
      //         // })
      //       })
      //       .catch((err) => {
      //         this.loading = false;
      //       });
      //   } else {
      //     return false;
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.store-tips {
  background: #ebf1ff;
  border: 1px solid #d7e4ff;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 20px;
  a {
    text-decoration: none;
    color: #3576ff;
  }
  .tips-title {
    color: #3576ff;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .tips-content {
    margin-left: 32px;
    .item-tip {
      display: flex;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  span + span {
    margin-left: 4px;
  }
}
a {
  text-decoration: none;
  color: #3576ff;
}

.list-form + .list-form {
  margin-top: 20px;
}

.list-form {
  position: relative;
  margin-top: 10px;
  width: 530px;
  padding: 20px;
  background-color: #f9f9f9;

  .del-btn {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 14px;
    color: #3576ff;
    z-index: 2;
  }
}

.add-btn {
  width: 530px;
  margin-top: 20px;
  border: 1px dashed #dcdfe6;

  &:hover {
    border: 1px dashed #3576ff;
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
    color: #606266;
  }
}
</style>
