/*
 * @Author: dongjia
 * @Date: 2021-06-28 13:59:07
 * @LastEditTime: 2021-06-28 14:01:36
 * @LastEditors: aleaner
 * @Description: 商店配置页面接口
 * @FilePath: \saas-admin-vue\src\modules\wx-store\api\store-config.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

// 获取商店配置详情
export const getConfig = data => {
  return api({
    url: '/admin/wx_open/wx_store_config/getConfig',
    method: 'post',
    data,
    notCancel: true
  });
};

// 保存商店配置详情
export const saveConfig = data => {
  return api({
    url: '/admin/wx_open/wx_store_config/saveConfig',
    method: 'post',
    data,
    notCancel: true
  });
};